import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Button from "react-bootstrap/Button";
import PhotoLeft from "../../components/photo-left";
import TreatmentHero from "../../components/treatment-hero";
import TreatmentCard from "../../components/treatment-card";
import ReviewSection from "../../components/review-section";
import TreatmentImageSection from "../../components/treatment-image-section";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Faq from "../../components/faq";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";
import uuid4 from "uuid4";

const VeneersAndBondingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
      }
      schemaLogo: wpMediaItem(title: { eq: "dark-logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "veneers-and-bonding" }) {
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        veneersAndBondingFieldGroups {
          veneersIntroSection {
            heading
            subHeading
            title
            subTitle
            description
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          veneersTextSection {
            content
            heading
          }
          veneersReviewSection {
            heading
            subHeading
          }
          veneersWhatYouKnowSection {
            heading
            subHeading
            cta {
              target
              title
              url
            }
            items {
              heading
              price
              content
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          veneersAdvantageSection {
            heading
            advantages
            disadvantages
          }
          veneersFaqSection {
            heading
            faqs {
              nodes {
                ... on WpFaq {
                  faqFields {
                    question
                    answer
                  }
                }
              }
            }
          }
          veneersTwoColumnSection {
            heading
            content
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          veneersGallerySection {
            heading
            subHeading
            images {
              nodes {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, veneersAndBondingFieldGroups, slug },
  } = data;

  const {
    veneersIntroSection,
    veneersTextSection,
    veneersReviewSection,
    veneersWhatYouKnowSection,
    veneersTwoColumnSection,
    veneersGallerySection,
    veneersAdvantageSection,
    veneersFaqSection,
  } = veneersAndBondingFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id":
      `https://www.dentalhouseexeter.co.uk/treatments/${slug}/#${slug}`,
    image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.dentalhouseexeter.co.uk/treatments/${slug}`,
    sku: `https://www.dentalhouseexeter.co.uk/treatments/${slug}`,
    description: `${seoFieldGroups?.metaDescription}`,
    logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
    name: "Dental House Exeter",
    url: "https://www.dentalhouseexeter.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Dental House Exeter",
      url: "https://www.dentalhouseexeter.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
    },
    review: {
      "@type": "Review",
      url: "https://www.dentalhouseexeter.co.uk/reviews",
      datePublished: "2022-03-21T17:58:29+00:00",
      reviewBody:
        "This is the best dental practice I have known. Not only in terms of expertise but the lovely sociability of every single member of the practice team.",
      author: {
        "@type": "Person",
        name: "Caroline P",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Dental House Exeter",
        sameAs: "https://www.dentalhouseexeter.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Treatments",
        item: {
          url: `${siteUrl}/treatments`,
          id: `${siteUrl}/treatments`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Veneers and Bonding",
        item: {
          url: `${siteUrl}/treatments/veneers-and-bonding`,
          id: `${siteUrl}/treatments/veneers-and-bonding`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/treatments/veneers-and-bonding`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {veneersIntroSection &&
          !checkPropertiesForNull(veneersIntroSection, ["heading"]) && (
            <TreatmentHero
              smallHeadingTopText={veneersIntroSection?.subHeading}
              img={
                veneersIntroSection?.image.node?.localFile.childImageSharp
                  .gatsbyImageData
              }
              imgAlt={veneersIntroSection?.image.node?.altText}
              heading1={veneersIntroSection?.heading}
              headingSmall={veneersIntroSection?.subTitle}
              heading2={veneersIntroSection?.title}
              text={
                <SafeHtmlParser
                  htmlContent={veneersIntroSection?.description}
                />
              }
              ctaButton={veneersIntroSection?.cta}
            />
          )}
        {veneersTextSection &&
          !checkPropertiesForNull(veneersTextSection, ["heading"]) && (
            <section className="py-5 py-lg-7">
              <Container>
                <Row>
                  <Col>
                    <h2 className=" fs-1 pb-4">
                      {veneersTextSection?.heading}
                    </h2>
                    <SafeHtmlParser htmlContent={veneersTextSection?.content} />
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        <ReviewSection
          heading={
            veneersReviewSection?.heading ||
            "Reviews From Our Dentistry Patients"
          }
          smallheading={
            veneersReviewSection?.subHeading || "WHAT PEOPLE SAY ABOUT US"
          }
          page="Veneers and bonding"
        />
        {veneersWhatYouKnowSection &&
          !checkPropertiesForNull(veneersWhatYouKnowSection, ["heading"]) && (
            <section className="pt-8 pb-6">
              <Container>
                <Row>
                  <Col className="text-center">
                    <p className="text-accent jost-bold fs-6">
                      {veneersWhatYouKnowSection?.subHeading}
                    </p>
                    <h2 className="text-text-secondary display-4 pb-4">
                      {veneersWhatYouKnowSection?.heading}
                    </h2>
                  </Col>
                </Row>
                {veneersWhatYouKnowSection.items &&
                  veneersWhatYouKnowSection.items.length > 0 && (
                    <Row className="pt-md-8 pt-5 h-100 justify-content-center">
                      {" "}
                      {veneersWhatYouKnowSection.items.map((cardItem) => (
                        <TreatmentCard
                          key={uuid4()}
                          image={
                            cardItem?.image.node?.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          imgAlt={cardItem?.image.node?.altText}
                          title={cardItem?.heading}
                          text={
                            <SafeHtmlParser htmlContent={cardItem?.content} />
                          }
                          span={4}
                          price={cardItem?.price}
                          shorter="image-shorter"
                        />
                      ))}
                    </Row>
                  )}
                {veneersWhatYouKnowSection.cta &&
                  veneersWhatYouKnowSection.cta.url && (
                    <Row>
                      <Col className="text-center mt-md-6">
                        {" "}
                        <Button
                          className="text-white py-3 px-5 w-100 w-md-auto"
                          variant="accent"
                          href={veneersWhatYouKnowSection.cta?.url}
                          target={veneersWhatYouKnowSection.cta?.target ?? "_blank"}
                          rel="noreferrer"
                        >
                          {veneersWhatYouKnowSection.cta?.title}
                        </Button>
                      </Col>
                    </Row>
                  )}
              </Container>
            </section>
          )}
        {veneersAdvantageSection &&
          !checkPropertiesForNull(veneersAdvantageSection, ["heading"]) && (
            <section className="py-5 py-lg-7 mt-5 mt-md-0 bg-primary content-list-advantage">
              <Container>
                <Row>
                  <Col>
                    <h2 className="text-white text-center fs-1 pb-5">
                      {veneersAdvantageSection?.heading}
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg={5}>
                    <SafeHtmlParser
                      htmlContent={veneersAdvantageSection?.advantages}
                    />
                  </Col>
                  <Col className="d-none d-lg-block text-center" lg={2}>
                    <div
                      style={{ width: "5px" }}
                      className="h-100 mx-auto bg-accent"
                    ></div>
                  </Col>
                  <Col lg={5}>
                    <SafeHtmlParser
                      htmlContent={veneersAdvantageSection?.disadvantages}
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        {veneersTwoColumnSection &&
          !checkPropertiesForNull(veneersTwoColumnSection, ["heading"]) && (
            <section>
              {" "}
              <PhotoLeft
                img={
                  veneersTwoColumnSection?.image.node?.localFile.childImageSharp
                    .gatsbyImageData
                }
                imgAlt={veneersTwoColumnSection?.image.node?.altText}
                text={
                  <div>
                    <h2 className=" text-center text-lg-start display-4 pb-4">
                      {veneersTwoColumnSection?.heading}
                    </h2>
                    <GatsbyImage
                      className="mb-4 w-100 d-lg-none"
                      image={
                        veneersTwoColumnSection?.image.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={veneersTwoColumnSection?.image.node?.altText}
                    />
                    <SafeHtmlParser
                      htmlContent={veneersTwoColumnSection?.content}
                    />
                    {veneersTwoColumnSection && veneersTwoColumnSection.cta && (
                      <Button
                        className="text-white py-3 px-5 w-100 w-md-auto"
                        variant="accent"
                        href={veneersTwoColumnSection?.cta.url}
                        target={veneersTwoColumnSection.cta.target ?? "_blank"}
                        rel="noreferrer"
                      >
                        {veneersTwoColumnSection?.cta.title}
                      </Button>
                    )}
                  </div>
                }
                bgColour="bg-bg"
              />
            </section>
          )}
        {veneersFaqSection &&
          !checkPropertiesForNull(veneersFaqSection, ["faqs"]) && (
            <Faq
              heading={veneersFaqSection?.heading}
              faqs={veneersFaqSection?.faqs?.nodes}
            />
          )}
        {veneersGallerySection &&
          !checkPropertiesForNull(veneersGallerySection, ["heading"]) && (
            <TreatmentImageSection
              images={veneersGallerySection?.images.nodes}
              heading={veneersGallerySection?.heading}
              headingSmall={veneersGallerySection?.subHeading}
            />
          )}
      </Layout>
    </>
  );
};

export default VeneersAndBondingPage;
